.hot100RedBackground {
  background: #F61703;
}

.top100-body::-webkit-scrollbar {
  display: none;
}
.top100-body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (min-width: 1240px) {
  .top100MetaverseHeaderContainer {
    width: 1240px;
    display: flex;
    justify-content: center;
  }

  .top100-body {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top100Column-Container {
    margin-bottom: 16px;
    margin-top: 16px;
    display: flex;
  }

  .middleColumnContainer {
    border-right: 3px solid #3B3A2D;
    border-left: 3px solid #3B3A2D;
    margin-left: 16px;
    margin-right: 16px;
  }

  .top100MetaverseThe {
    font-size: 16px;
    font-family: 'Noto Serif';
    font-weight: 700;
    text-align: center;
    color: #EFEDDE;
  }

  .top100Header {
    font-family: 'Noto Serif';
    font-style: italic;
    font-weight: 700;
    font-size: 110px;
    margin-top: 40px;
    line-height: 136px;
    text-align: center;
    color: #F61703;
  }

  .top100-bodyContainer {
    background: #EFEDDE;
    border: 20px solid #35352A;
    width: 1200px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 70px;
  }

  .top100Row {
    display: flex;
    width: 352px;
    margin-top: 16px;
  }

  .top100-titlePlatformContainer {
    display: flex;
    flex-direction: column;
    width: 210px;
  }

  .top100MetaverseHeader {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    text-align: center;
    background: #35352A;
    color: #EFEDDE;
    width: 300px;
    position: absolute;
    top: 60px;
  }

  .top100Column1Container {
    display: flex;
    flex-direction: column;
  }

  .top100-week-box {
    display: flex;
    border: 1px solid #000000;
    align-items: center;
    height: 60px;
    justify-content: flex-start;
  }

  .top100-week-column-container {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-right: 1px solid #000000;
    display: flex;
    justify-content: center;
  }

  .top100-week-column-container-weeksonchart {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-left: 1px solid #000000;
    display: flex;
    justify-content: center;
  }

  .top100-week-column-container-thisweek {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-right: 1px solid #000000;
    display: flex;
    justify-content: center;
    background: #3B3A2D;
    color: #EFEDDE;
  }

  .top100-week-column {
    font-family: 'Futura';
    font-style: normal;
    font-size: 8px;
    line-height: 8px;
    text-align: center;
    transform: rotate(-90deg);
  }

  .top100-week-column-thisweek {
    font-family: 'Futura';
    font-style: normal;
    transform: rotate(-90deg);
    font-size: 10px;
    line-height: 12px;
    border-radius: 1px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .top100-week-rank-container {
    width: 28px;
    display: flex;
    justify-content: center;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  .top100columnType {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    width: 210px;
    display: flex;
    justify-content: space-around;
  }

  .top100Header-Container {
    width: 1240px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top100-numberContainer {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top100-titleContainer {
    border-bottom: 2.5px dotted #000000;
    width: 210px;
  }

  .top100-title {
    color: #3B3A2D;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }

  .top100-subText {
    display: flex;
    justify-content: space-between;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #000000;
  }

  .top100-connectWalletContainer {
    position: absolute;
    right: 32px;
    top: 20px;
    cursor: pointer;
    font-size: 14px;
  }

  .top100-emptyState {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    border: 20px solid #35352A;
    border-radius: 70px;
    width: 1200px;
    background: #EFEDDE;;
    height: 500px;
    margin-top: 80px;
  }

  .connectButtonEmptyState {
    font-size: 28px;
  }

  .emptyStateMessage {
    font-size: 32px;
    margin-bottom: 16px;
  }
}

@media (min-width: 769px) and (max-width: 1239px) {
  .top100MetaverseHeaderContainer {
    width: 768px;
    display: flex;
    justify-content: center;
  }

  .top100-body {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top100Column-Container {
    margin-bottom: 16px;
  }

  .top100MetaverseThe {
    font-size: 12px;
    font-family: 'Noto Serif';
    font-weight: 700;
    text-align: center;
    color: #EFEDDE;
  }

  .top100Header {
    font-family: 'Noto Serif';
    font-style: italic;
    font-weight: 700;
    font-size: 60px;
    line-height: 136px;
    text-align: center;
    color: #F61703;
  }

  .top100-bodyContainer {
    background: #EFEDDE;
    border: 20px solid #35352A;
    width: 728px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 70px;
  }

  .top100Row {
    display: flex;
    width: 352px;
    margin-top: 16px;
  }

  .top100-titlePlatformContainer {
    display: flex;
    flex-direction: column;
    width: 210px;
  }

  .top100MetaverseHeader {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    background: #35352A;
    text-align: center;
    color: #EFEDDE;
    width: 300px;
    top: 70px;
    position: absolute;
    background: #35352A;
  }

  .top100Header-Container {
    width: 768px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top100-numberContainer {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top100-titleContainer {
    border-bottom: 2.5px dotted #000000;
    width: 210px;
  }

  .top100-title {
    color: #3B3A2D;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }

  .top100-subText {
    display: flex;
    justify-content: space-between;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #000000;
  }

  .top100-connectWalletContainer {
    position: absolute;
    right: 32px;
    top: 20px;
    cursor: pointer;
    font-size: 14px;
  }

  .top100-emptyState {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    border: 20px solid #35352A;
    border-radius: 70px;
    width: 728px;
    background: #EFEDDE;;
    height: 500px;
    margin-top: 80px;
  }

  .connectButtonEmptyState {
    font-size: 28px;
  }

  .emptyStateMessage {
    font-size: 32px;
    margin-bottom: 16px;
  }

  .top100Column1Container {
    display: flex;
    flex-direction: column;
  }

  .top100-week-box {
    display: flex;
    border: 1px solid #000000;
    align-items: center;
    height: 60px;
    justify-content: flex-start;
  }

  .top100-week-column-container {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-right: 1px solid #000000;
    display: flex;
    justify-content: center;
  }

  .top100-week-column-container-weeksonchart {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-left: 1px solid #000000;
    display: flex;
    justify-content: center;
  }

  .top100-week-column-container-thisweek {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-right: 1px solid #000000;
    display: flex;
    justify-content: center;
    background: #3B3A2D;
    color: #EFEDDE;
  }

  .top100-week-column {
    font-family: 'Futura';
    font-style: normal;
    font-size: 8px;
    line-height: 8px;
    text-align: center;
    transform: rotate(-90deg);
  }

  .top100-week-column-thisweek {
    font-family: 'Futura';
    font-style: normal;
    transform: rotate(-90deg);
    font-size: 10px;
    line-height: 12px;
    border-radius: 1px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .top100-week-rank-container {
    width: 28px;
    display: flex;
    justify-content: center;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  .top100columnType {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    width: 210px;
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .top100-body {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top100Column-Container {
    margin-bottom: 16px;
  }

  .top100MetaverseThe {
    font-size: 12px;
    font-family: 'Noto Serif';
    font-weight: 700;
    text-align: center;
    color: #EFEDDE;
  }

  .top100Header {
    font-family: 'Noto Serif';
    font-style: italic;
    font-weight: 700;
    font-size: 80px;
    line-height: 136px;
    text-align: center;
    color: #EFEDDE;
    margin-top: 20px;
  }

  .top100-bodyContainer {
    background: #EFEDDE;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .top100Row {
    display: flex;
    width: 352px;
    margin-top: 16px;
  }

  .top100-titlePlatformContainer {
    display: flex;
    flex-direction: column;
    width: 210px;
  }

  .top100MetaverseHeader {
    display: none;
  }

  .top100Header-Container {
    height: 172px;
    background: #F61703;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top100-numberContainer {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top100-titleContainer {
    border-bottom: 2.5px dotted #000000;
    width: 210px;
  }

  .top100-title {
    color: #3B3A2D;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }

  .top100-subText {
    display: flex;
    justify-content: space-between;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #000000;
  }

  .top100-connectWalletContainer {
    position: absolute;
    right: 10px;
    top: 6px;
    z-index: 0;
    cursor: pointer;
    font-size: 14px;
  }

  .top100-emptyState {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    text-align: center;
    width: 100vw;
    background: #EFEDDE;;
    height: 100vh;
  }

  .connectButtonEmptyState {
    font-size: 28px;
  }

  .emptyStateMessage {
    font-size: 32px;
    margin-bottom: 16px;
  }

  .top100Column1Container {
    display: flex;
    flex-direction: column;
  }

  .top100-week-box {
    display: flex;
    border: 1px solid #000000;
    align-items: center;
    height: 60px;
    justify-content: flex-start;
    margin-top: 16px;
  }

  .top100-week-column-container {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-right: 1px solid #000000;
    display: flex;
    justify-content: center;
  }

  .top100-week-column-container-weeksonchart {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-left: 1px solid #000000;
    display: flex;
    justify-content: center;
  }

  .top100-week-column-container-thisweek {
    width: 28px;
    height: 60px;
    align-items: center;
    border-radius: 1px;
    border-right: 1px solid #000000;
    display: flex;
    justify-content: center;
    background: #3B3A2D;
    color: #EFEDDE;
  }

  .top100-week-column {
    font-family: 'Futura';
    font-style: normal;
    font-size: 8px;
    line-height: 8px;
    text-align: center;
    transform: rotate(-90deg);
  }

  .top100-week-column-thisweek {
    font-family: 'Futura';
    font-style: normal;
    transform: rotate(-90deg);
    font-size: 10px;
    line-height: 12px;
    border-radius: 1px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .top100-week-rank-container {
    width: 28px;
    display: flex;
    justify-content: center;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  .top100columnType {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    width: 210px;
    display: flex;
    justify-content: space-around;
  }
}

.top100Spinner {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-top: 250px;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
